<template>
	<div class="w-full relative">
		<div class="flex justify-between items-center">
			<h1 class="text-xl font-extrabold sm:text-2xl">Результаты НОК текущего года</h1>
			<div class="flex items-center">
				<div class="checkbox mr-2">
					<label class="flex"><input type="checkbox" @change="getRandom()" v-model="params.isRandom600" class="mr-2 check-big" />Получить выборку 600 анкет</label>
				</div>
				<Button text="Таблица для bus.gov.ru" color="white" @click.native="downloadBusGovExcel()" class="mr-2" />
				<Button text="Экспорт в Excel" icon="exel" @click.native="upLoadExcel(params)" :disabled="loadToExcell" :load="loadToExcell" />
			</div>
		</div>
		<div class="table-respons">
			<table>
				<thead>
					<tr>
						<th rowspan="3" class="th-sort w-32 sticky-table__header1">
							<p @click="switchFilterVisibility(0)">Наименование МО</p>
							<FilterComponent
								v-on:change="switchFilterVisibility(null)"
								position="left"
								:filterOpen="filterOpen"
								:id-filter="0"
								:param="params"
								@query="getData"
								:fields="fields"
								:item="fields[0]"
							/>
						</th>
						<th rowspan="3">
							<p>Условия оказания</p>
						</th>
						<th rowspan="3" class="th-sort">
							<p @click="switchFilterVisibility(2)">Итоговый результат по условиям оказания</p>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="2" :param="params" @query="getData" :fields="fields" :item="fields[2]" />
						</th>
						<th rowspan="3" class="th-sort">
							<p @click="switchFilterVisibility(3)">Количество анкет</p>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="3" :param="params" @query="getData" :fields="fields" :item="fields[3]" />
						</th>
						<th colspan="20">
							<p>Показатели, характеризующие общие критерии оценки качества условий оказания услуг медицинскими организациями, в отношении которых проводится независимая оценка</p>
						</th>
					</tr>
					<tr>
						<th colspan="4"><p>Открытость и доступность информации об организации</p></th>
						<th colspan="4"><p>Комфортность условий предоставления услуг, включая время ожидания предоставления медицинской услуги</p></th>
						<th colspan="4"><p>Доступность услуг для инвалидов</p></th>
						<th colspan="4"><p>Доброжелательность, вежливость работников медицинской организации</p></th>
						<th colspan="4"><p>Удовлетворенность условиями оказания услуг</p></th>
					</tr>
					<tr>
						<th><p>1</p></th>
						<th><p>1.1</p></th>
						<th><p>1.2</p></th>
						<th><p>1.3</p></th>
						<th><p>2</p></th>
						<th><p>2.1</p></th>
						<th><p>2.2</p></th>
						<th><p>2.3</p></th>
						<th><p>3</p></th>
						<th><p>3.1</p></th>
						<th><p>3.2</p></th>
						<th><p>3.3</p></th>
						<th><p>4</p></th>
						<th><p>4.1</p></th>
						<th><p>4.2</p></th>
						<th><p>4.3</p></th>
						<th><p>5</p></th>
						<th><p>5.1</p></th>
						<th><p>5.2</p></th>
						<th><p>5.3</p></th>
					</tr>
				</thead>
				<template v-for="item in yearResult">
					<tbody @click="openRow(item)" :key="item.medicalId" :class="{ 'bg-gray-30': item.medicalId === opened }">
						<tr>
							<td rowspan="5" class="w-32 sticky-table__row1" :class="{ 'th-sort underline cursor-pointer': item.hasBranch }">
								<p>{{ item.name }}</p>
							</td>
							<td v-if="item.ambulator">А</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_Result }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_Count }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_1 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_1_1 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_1_2 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_1_3 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_2 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_2_1 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_2_2 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_2_3 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_3 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_3_1 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_3_2 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_3_3 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_4 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_4_1 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_4_2 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_4_3 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_5 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_5_1 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_5_2 }}</p>
							</td>
							<td v-if="item.ambulator">
								<p>{{ item.a_5_3 }}</p>
							</td>
						</tr>
						<tr v-if="item.stacionar">
							<td>C</td>
							<td>
								<p>{{ item.s_Result }}</p>
							</td>
							<td>
								<p>{{ item.s_Count }}</p>
							</td>
							<td>
								<p>{{ item.s_1 }}</p>
							</td>
							<td>
								<p>{{ item.s_1_1 }}</p>
							</td>
							<td>
								<p>{{ item.s_1_2 }}</p>
							</td>
							<td>
								<p>{{ item.s_1_3 }}</p>
							</td>
							<td>
								<p>{{ item.s_2 }}</p>
							</td>
							<td>
								<p>{{ item.s_2_1 }}</p>
							</td>
							<td>
								<p>{{ item.s_2_2 }}</p>
							</td>
							<td>
								<p>{{ item.s_2_3 }}</p>
							</td>
							<td>
								<p>{{ item.s_3 }}</p>
							</td>
							<td>
								<p>{{ item.s_3_1 }}</p>
							</td>
							<td>
								<p>{{ item.s_3_2 }}</p>
							</td>
							<td>
								<p>{{ item.s_3_3 }}</p>
							</td>
							<td>
								<p>{{ item.s_4 }}</p>
							</td>
							<td>
								<p>{{ item.s_4_1 }}</p>
							</td>
							<td>
								<p>{{ item.s_4_2 }}</p>
							</td>
							<td>
								<p>{{ item.s_4_3 }}</p>
							</td>
							<td>
								<p>{{ item.s_5 }}</p>
							</td>
							<td>
								<p>{{ item.s_5_1 }}</p>
							</td>
							<td>
								<p>{{ item.s_5_2 }}</p>
							</td>
							<td>
								<p>{{ item.s_5_3 }}</p>
							</td>
						</tr>
						<tr v-if="item.healthResort">
							<td>К</td>
							<td>
								<p>{{ item.k_Result }}</p>
							</td>
							<td>
								<p>{{ item.k_Count }}</p>
							</td>
							<td>
								<p>{{ item.k_1 }}</p>
							</td>
							<td>
								<p>{{ item.k_1_1 }}</p>
							</td>
							<td>
								<p>{{ item.k_1_2 }}</p>
							</td>
							<td>
								<p>{{ item.k_1_3 }}</p>
							</td>
							<td>
								<p>{{ item.k_2 }}</p>
							</td>
							<td>
								<p>{{ item.k_2_1 }}</p>
							</td>
							<td>
								<p>{{ item.k_2_2 }}</p>
							</td>
							<td>
								<p>{{ item.k_2_3 }}</p>
							</td>
							<td>
								<p>{{ item.k_3 }}</p>
							</td>
							<td>
								<p>{{ item.k_3_1 }}</p>
							</td>
							<td>
								<p>{{ item.k_3_2 }}</p>
							</td>
							<td>
								<p>{{ item.k_3_3 }}</p>
							</td>
							<td>
								<p>{{ item.k_4 }}</p>
							</td>
							<td>
								<p>{{ item.k_4_1 }}</p>
							</td>
							<td>
								<p>{{ item.k_4_2 }}</p>
							</td>
							<td>
								<p>{{ item.k_4_3 }}</p>
							</td>
							<td>
								<p>{{ item.k_5 }}</p>
							</td>
							<td>
								<p>{{ item.k_5_1 }}</p>
							</td>
							<td>
								<p>{{ item.k_5_2 }}</p>
							</td>
							<td>
								<p>{{ item.k_5_3 }}</p>
							</td>
						</tr>
						<tr v-if="item.psychiatric">
							<td>П</td>
							<td>
								<p>{{ item.p_Result }}</p>
							</td>
							<td>
								<p>{{ item.p_Count }}</p>
							</td>
							<td>
								<p>{{ item.p_1 }}</p>
							</td>
							<td>
								<p>{{ item.p_1_1 }}</p>
							</td>
							<td>
								<p>{{ item.p_1_2 }}</p>
							</td>
							<td>
								<p>{{ item.p_1_3 }}</p>
							</td>
							<td>
								<p>{{ item.p_2 }}</p>
							</td>
							<td>
								<p>{{ item.p_2_1 }}</p>
							</td>
							<td>
								<p>{{ item.p_2_2 }}</p>
							</td>
							<td>
								<p>{{ item.p_2_3 }}</p>
							</td>
							<td>
								<p>{{ item.p_3 }}</p>
							</td>
							<td>
								<p>{{ item.p_3_1 }}</p>
							</td>
							<td>
								<p>{{ item.p_3_2 }}</p>
							</td>
							<td>
								<p>{{ item.p_3_3 }}</p>
							</td>
							<td>
								<p>{{ item.p_4 }}</p>
							</td>
							<td>
								<p>{{ item.p_4_1 }}</p>
							</td>
							<td>
								<p>{{ item.p_4_2 }}</p>
							</td>
							<td>
								<p>{{ item.p_4_3 }}</p>
							</td>
							<td>
								<p>{{ item.p_5 }}</p>
							</td>
							<td>
								<p>{{ item.p_5_1 }}</p>
							</td>
							<td>
								<p>{{ item.p_5_2 }}</p>
							</td>
							<td>
								<p>{{ item.p_5_3 }}</p>
							</td>
						</tr>
						<tr class="bold">
							<td>Итог</td>
							<td>
								<p>{{ item.r_Result }}</p>
							</td>
							<td>
								<p>{{ item.r_Count }}</p>
							</td>
							<td>
								<p>{{ item.r_1 }}</p>
							</td>
							<td>
								<p>{{ item.r_1_1 }}</p>
							</td>
							<td>
								<p>{{ item.r_1_2 }}</p>
							</td>
							<td>
								<p>{{ item.r_1_3 }}</p>
							</td>
							<td>
								<p>{{ item.r_2 }}</p>
							</td>
							<td>
								<p>{{ item.r_2_1 }}</p>
							</td>
							<td>
								<p>{{ item.r_2_2 }}</p>
							</td>
							<td>
								<p>{{ item.r_2_3 }}</p>
							</td>
							<td>
								<p>{{ item.r_3 }}</p>
							</td>
							<td>
								<p>{{ item.r_3_1 }}</p>
							</td>
							<td>
								<p>{{ item.r_3_2 }}</p>
							</td>
							<td>
								<p>{{ item.r_3_3 }}</p>
							</td>
							<td>
								<p>{{ item.r_4 }}</p>
							</td>
							<td>
								<p>{{ item.r_4_1 }}</p>
							</td>
							<td>
								<p>{{ item.r_4_2 }}</p>
							</td>
							<td>
								<p>{{ item.r_4_3 }}</p>
							</td>
							<td>
								<p>{{ item.r_5 }}</p>
							</td>
							<td>
								<p>{{ item.r_5_1 }}</p>
							</td>
							<td>
								<p>{{ item.r_5_2 }}</p>
							</td>
							<td>
								<p>{{ item.r_5_3 }}</p>
							</td>
						</tr>
					</tbody>
					<template v-if="item.medicalId === opened">
						<tbody v-for="subitem in item.branches" :key="subitem.medicalId" :class="{ 'bg-gray-30': item.medicalId === opened }">
							<tr>
								<td rowspan="5">
									<div class="flex items-center">
										<div v-if="subitem.isBranch" class="default-filial-clone">Филиал</div>
										<div v-if="subitem.isClone" class="default-filial-clone-2 ml-2">Вторичная МО</div>
									</div>
									<p>{{ subitem.name }}</p>
								</td>
								<td v-if="subitem.ambulator">А</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_Result }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_Count }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_1 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_1_1 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_1_2 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_1_3 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_2 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_2_1 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_2_2 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_2_3 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_3 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_3_1 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_3_2 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_3_3 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_4 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_4_1 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_4_2 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_4_3 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_5 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_5_1 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_5_2 }}</p>
								</td>
								<td v-if="subitem.ambulator">
									<p>{{ subitem.a_5_3 }}</p>
								</td>
							</tr>
							<tr v-if="subitem.stacionar">
								<td>C</td>
								<td>
									<p>{{ subitem.s_Result }}</p>
								</td>
								<td>
									<p>{{ subitem.s_Count }}</p>
								</td>
								<td>
									<p>{{ subitem.s_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_1_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_1_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_1_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_2_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_2_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_2_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_3_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_3_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_3_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_4 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_4_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_4_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_4_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_5 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_5_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_5_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.s_5_3 }}</p>
								</td>
							</tr>
							<tr v-if="subitem.healthResort">
								<td>К</td>
								<td>
									<p>{{ subitem.k_Result }}</p>
								</td>
								<td>
									<p>{{ subitem.k_Count }}</p>
								</td>
								<td>
									<p>{{ subitem.k_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_1_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_1_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_1_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_2_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_2_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_2_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_3_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_3_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_3_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_4 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_4_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_4_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_4_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_5 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_5_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_5_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.k_5_3 }}</p>
								</td>
							</tr>
							<tr v-if="subitem.psychiatric">
								<td>П</td>
								<td>
									<p>{{ subitem.p_Result }}</p>
								</td>
								<td>
									<p>{{ subitem.p_Count }}</p>
								</td>
								<td>
									<p>{{ subitem.p_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_1_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_1_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_1_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_2_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_2_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_2_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_3_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_3_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_3_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_4 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_4_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_4_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_4_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_5 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_5_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_5_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.p_5_3 }}</p>
								</td>
							</tr>
							<tr class="bold">
								<td>Итог</td>
								<td>
									<p>{{ subitem.r_Result }}</p>
								</td>
								<td>
									<p>{{ subitem.r_Count }}</p>
								</td>
								<td>
									<p>{{ subitem.r_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_1_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_1_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_1_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_2_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_2_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_2_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_3_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_3_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_3_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_4 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_4_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_4_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_4_3 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_5 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_5_1 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_5_2 }}</p>
								</td>
								<td>
									<p>{{ subitem.r_5_3 }}</p>
								</td>
							</tr>
						</tbody>
					</template>
				</template>
			</table>
			<center v-if="!loadingTable && yearResult.length === 0" class="w-full py-28 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">Данных нет...</center>
			<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
				<loadingComponent class="absolute top-1/2 left-1/2" />
			</div>
		</div>
		<Pagination :page="page" :totalCount="totalCount" :params="params" @query="getData" />
	</div>
</template>

<script>
import Button from '@/components/Button.vue';
import FilterComponent from '@/components/Filter.vue';
import Pagination from '@/components/Pagination.vue';
// Глобальные данные стора
import { getters, actions, mutations, methods } from '@/store/store';
// Локальные данные стора
import loadingComponent from '@/components/Loading.vue';
import actionsLocal from '../store/actions';

export default {
	name: 'TableProtocols',
	components: {
		loadingComponent,
		Button,
		FilterComponent,
		Pagination
	},
	data() {
		return {
			rowsVisible: 5,
			totalCount: 0,
			openDelet: false,
			page: 0,
			region: null,
			yearResult: [],
			open: false,
			filterOpen: null,
			filterBy: null,
			popupTitle: '',
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: ''
					},
					{
						fieldName: 'isMain',
						filterType: 1,
						filterValueType: 1,
						value1: 'true'
					}
				],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				pageSize: 5,
				currentPage: 0,
				isRandom600: false,
				columns: []
			},
			fields: [
				{
					Name: 'Наименование МО',
					fieldName: 'name',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Условия оказания',
					fieldName: 'r_Result',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Итоговый результат по условиям оказания',
					fieldName: 'r_Result',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Количество анкет',
					fieldName: 'r_Count',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			opened: null,
			loadToExcell: false,
			loadingTable: false
		};
	},
	computed: {
		...getters
	},
	methods: {
		...mutations,
		...actions,
		...actionsLocal,
		...methods,
		getRandom() {
			this.getData(this.params);
		},
		openRow(item) {
			if (item.hasBranch) {
				if (this.opened === item.medicalId) {
					this.opened = null;
				} else {
					this.opened = item.medicalId;
				}
			}
		},
		upLoadExcel(params) {
			this.loadToExcell = true;
			this.$http
				.post(`MedicalOrgs/GetResultForYearExcel?isRandom600=${params.isRandom600}`, params, {
					responseType: 'blob',
					headers: {
						'Access-Control-Expose-Headers': 'Content-Disposition'
					}
				})
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.region.name}_Результаты НОК текущего год.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
				});
		},
		downloadBusGovExcel() {
			this.loadToExcell = true;

			this.$http
				.post(
					`MedicalOrgs/GetBusGovRuExcel?RegionId=${this.region.id}&Year=${this.getYears.currentYear}`,
					{},
					{
						responseType: 'blob',
						headers: {
							'Access-Control-Expose-Headers': 'Content-Disposition'
						}
					}
				)
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.region.name}_${this.$moment().format('YYYY')}_Таблица для busgov.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
				});
		},
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		getData(params) {
			this.loadingTable = true;
			this.getResultForYearByRegion(params).then((res) => {
				this.yearResult = res.data.data;
				this.totalCount = Math.ceil(res.data.totalRecords / this.params.pageSize);
				this.loadingTable = false;
			});
		}
	},
	mounted() {
		this.getCurrentRegion().then((result) => {
			this.region = result;
		});

		let regionId = this.getRegionId();
		this.params.filters[0].value1 = regionId;

		this.getRegionYearData(regionId).then(() => {
			this.params.filters.push({
				fieldName: 'YearStr',
				filterType: 1,
				filterValueType: 1,
				value1: this.getYears.currentYear.toString(),
				value2: ''
			});
			this.getData(this.params);
		});
	}
};
</script>

<style scoped>
.sticky-table__header1 {
	left: 0;
	z-index: 9;
	position: sticky !important;
	text-align: center;
	background-color: rgba(255, 255, 255);
}

.sticky-table__row1 {
	left: 0;
	z-index: 1;
	position: sticky !important;
}

.default-filial-clone,
.default-filial-clone-2 {
	padding: 4px 8px;
	font-weight: 400;
	font-size: 13px;
	line-height: 20px;
	background: #a8b1ce;
	border-radius: 4px;
	width: 110px;
	color: #fff;
	text-align: center;
}
.table-respons td,
.table-respons th {
	padding: 20px 30px;
	vertical-align: center;
	text-align: center;
	border-bottom: 1px solid #f2f5ff;
	border-right: 1px solid #f2f5ff;
	position: relative;
}

th {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

td {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

.table-respons {
	min-height: 385px;
	position: relative;
	overflow: auto;
	width: 100%;
	margin-top: 20px;
}

.table-respons .th-sort {
	text-decoration-line: underline;
	cursor: pointer;
}

.bold td {
	font-weight: 600;
}

.table-respons {
	background: #fff;
	border-radius: 10px 10px 0 0;
	box-shadow: 0px 4px 17px rgba(171, 184, 225, 0.4);
}

.table-respons::-webkit-scrollbar {
	width: 11px; /* ширина всей полосы прокрутки */
}

.table-respons::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.table-respons::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}
</style>
